import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import Typography from "../components/Typography";
import Contact from "./Contact";

const styles = (theme) => ({
  root: {
    display: "flex",
    overflow: "hidden",
    paddingBottom: theme.spacing(4),
  },
  container: {
    marginTop: theme.spacing(10),
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 5),
  },
  title: {
    marginBottom: theme.spacing(4),
    textAlign: "center",
  },
  image: {
    height: 55,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  large: {
    height: "200px",
    width: "200px",
  },
  button: {
    marginTop: theme.spacing(8),
  },
});

function OurTeam(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container id="our-team-anchor" className={classes.container}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              marked="center"
              className={classes.title}
              component="h2"
            >
              Contact Us
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Contact />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              marked="center"
              className={classes.title}
              component="h2"
            >
              Our Team
            </Typography>
          </Grid>

          <Grid item xs={12} md={3}>
            <div className={classes.item}>
              <Avatar
                alt="Y-Sin-Son Charley"
                src="https://darkstarcdn.s3.us-east-2.amazonaws.com/ecsavatars/Y-Sin-Son.jpg"
                className={classes.large}
              />
              <Typography variant="h6" align="center">
                Y-Sin-Son Charley
              </Typography>
              <Typography variant="h5" align="center">
                Project Supervisor
              </Typography>
              <Typography variant="h5" align="center">
                (808) 561-0683
              </Typography>
              <Typography variant="h5" align="center">
                smh@smh-hi.com
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <div className={classes.item}>
              <Avatar
                alt="Joseph Santos"
                src="https://darkstarcdn.s3.us-east-2.amazonaws.com/ecsavatars/JoeS_avatar.jpg"
                className={classes.large}
              />
              <Typography variant="h6" align="center">
                Joseph Santos
              </Typography>
              <Typography variant="h5" align="center">
                Senior Project Manager
              </Typography>
              <Typography variant="h5" align="center">
                (808) 509-4717
              </Typography>
              <Typography variant="h5" align="center">
                smh@smh-hi.com
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <div className={classes.item}>
              <Avatar
                alt="Annika Cosio"
                src="https://darkstarcdn.s3.us-east-2.amazonaws.com/ecsavatars/Annika_avatar.jpg"
                className={classes.large}
              />
              <Typography variant="h6" align="center">
                Annika Cosio
              </Typography>
              <Typography variant="h5" align="center">
                Project Coordinator
              </Typography>
              <Typography variant="h5" align="center">
                (808) 841-7602
              </Typography>
              <Typography variant="h5" align="center">
                smh@smh-hi.com
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} md={3}>
            <div className={classes.item}>
              <Avatar
                alt="Sarah Light"
                src="https://darkstarcdn.s3.us-east-2.amazonaws.com/ecsavatars/Sarah_avatar.jpg"
                className={classes.large}
              />
              <Typography variant="h6" align="center">
                Sarah Light
              </Typography>
              <Typography variant="h5" align="center">
                Chief Operating Officer
              </Typography>
              <Typography variant="h5" align="center">
                (808) 306-4500
              </Typography>
              <Typography variant="h5" align="center">
                smh@smh-hi.com
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <div className={classes.item}>
              <Avatar
                alt="Joe Miller"
                src="https://darkstarcdn.s3.us-east-2.amazonaws.com/ecsavatars/joemiller.jpg"
                className={classes.large}
              />
              <Typography variant="h6" align="center">
                Joe Miller
              </Typography>
              <Typography variant="h5" align="center">
                President & CEO
              </Typography>
              <Typography variant="h5" align="center">
                (808) 841-7602
              </Typography>
              <Typography variant="h5" align="center">
                smh@smh-hi.com
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

OurTeam.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OurTeam);
