export const listKahalemoiNoticesNotices = /* GraphQL */ `
  query ListKahalemoiNoticesNotices {
    listNotices(
      filter: { clientId: { eq: "bf8086a5-f898-4d11-adf0-84c5b078ede1" } }
    ) {
      nextToken
      items {
        groupId
        pdfSource
        title
        id
        imgSource
      }
    }
  }
`;
